import produce from 'immer';

import AuthService from './';
import { SET_TOKEN, LOG_OUT } from './actions';

const initialState = {
  token: null,
};

export default function(state = initialState, { type, payload, meta }) {
  return produce(state, draft => {
    switch (type) {
      case SET_TOKEN:
        draft.token = payload;
        break;
      case LOG_OUT:
        draft.token = null;
        break;
    }
  });
}
