import produce from 'immer';

import { TOGGLE_IS_TRANSLATING, SET_TRANSLATING_ITEM } from './actions';

const initialState = {
  isTranslating: false,
  translatingItem: null,
};

export default function(state = initialState, { type, payload, meta }) {
  return produce(state, draft => {
    switch (type) {
      case TOGGLE_IS_TRANSLATING:
        draft.isTranslating = !draft.isTranslating;
        break;

      case SET_TRANSLATING_ITEM:
        draft.translatingItem = payload;
        break;
    }
  });
}
