import React from 'react';
import App, { Container } from 'next/app';
import { Provider } from 'react-redux';
import withRedux from 'next-redux-wrapper';
import flow from 'lodash.flow';
import * as Sentry from '@sentry/browser';

import { appWithTranslation } from '../i18n';
import createStore from '../store/createStore';
import AuthService from '../services/Auth';
import { setToken } from '../services/Auth/actions';
import PageLoadingBar from '../components/PageLoadingBar';

Sentry.init({
  dsn: 'https://42bf585a143341e2bf8a4f4774638bfa@sentry.io/1535499',
  enabled: process.env.NODE_ENV !== 'development',
});

class MyApp extends App {
  static async getInitialProps({ Component, ctx }) {
    const token = AuthService.getTokenFromStorage(ctx);
    ctx.store.dispatch(setToken(token));

    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    return { pageProps };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps, store } = this.props;
    return (
      <Container>
        <Provider store={store}>
          <PageLoadingBar />
          <Component {...pageProps} />
        </Provider>
      </Container>
    );
  }
}

export default flow(
  appWithTranslation,
  withRedux(createStore),
)(MyApp);
